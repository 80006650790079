import React from "react";
import { Box } from "@mui/material";
import DiscountCard from "../Discounts/DiscountCard";
import enLocale from "date-fns/locale/en-GB";
import theme from "../../styles/theme";

export default function DiscountsSection({ discounts }: { discounts: any }) {
  return (
    <Box
      sx={{
        display: { xs: "flex", md: "grid" },
        mt: 2,
        overflowX: "scroll",
        gridTemplateColumns: "repeat(4, 1fr)",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      {discounts?.slice(0, 4).map((discount: any, i: number) => (
        <Box
          key={i}
          sx={{
            minWidth: 250,
            [theme.breakpoints.down("md")]: {
              width: 250,
            },
          }}
        >
          <DiscountCard discount={discount} dateLocale={enLocale} />
        </Box>
      ))}
    </Box>
  );
}
