import { Box, Container } from "@mui/material";
import OtherDronersSection from "../src/components/Sections/OtherDronersSection";
import { LargeLandingHeroSection } from "../src/components/Sections/LargeLandingHeroSection";
import { NextSeo } from "next-seo";
import { HomeTitleSection } from "../src/components/CommonComponents/HomeTitleSection";
import { ContentProvider } from "../services/content/contentProvider";
import React, { useContext } from "react";
import { EventsContext, LocalizationContext } from "../src/lib/context";
import { getIndexDroners, getIndexProjects } from "../src/lib/indexPage";
import { useScreenSize } from "../src/hooks/useScreenSize";
import LargeLanding from "../src/components/LargeLanding";
import SmallLanding from "../src/components/SmallLanding";
import { SmallLandingHeroSection } from "../src/components/SmallLandingHeroSection";
import { DiscountType } from "../src/types/discountTypes";
import DiscountsSection from "../src/components/Sections/DiscountsSection";
import { GetStaticProps, GetStaticPropsContext } from "next";
import { IndexDronersType, IndexProjectType } from "../src/types/indexTypes";
import { DroneBanner } from "../src/components/Sections/DroneBanner";
import Inspirations from "../src/components/Sections/Inspirations";
import PromotionalCard from "../src/components/CommonComponents/PromotionalCard";
import Webinar from "../src/components/Sections/Webinar";
import PotentialDroners from "../src/components/Sections/PotentialDroners";
import PricingBanner from "../src/components/Sections/PricingBanner";
import { useUserData } from "../src/hooks/useUserData";
import { JobsSection } from "../src/components/JobsSection";
import { Event } from "../src/types/eventTypes";
import { Timestamp } from "@firebase/firestore-types";
import { DbProvider } from "../services/db/DbProvider";

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const jobsQueryName = "allTenders";
  const jobsContentProvider = new ContentProvider("jobs");
  const jobsQuery = jobsContentProvider.generateQuery(
    jobsQueryName,
    [
      {
        fields: [
          "id",
          "title",
          "country",
          "place",
          "projectType",
          "exampleImages {url}",
          "offerStart",
          "offerDeadline",
          "uploadDeadline",
        ],
      },
    ],
    { orderBy: "offerDeadline_ASC", fallbackLocales: "en", first: 100 }
  );
  const discountQueryName = "allDiscounts";
  const discountContentProvider = new ContentProvider("discounts");
  const query = discountContentProvider.generateQuery(
    discountQueryName,
    [
      {
        fields: [
          "id",
          "language",
          "endDate",
          "startDate",
          { name: "images", fields: ["url", "alt"] },
          "priceDiscGross",
          "discount",
          "domain",
          "priceOrigGross",
          "priceOrigNet",
          "currency",
          "shortName",
          "prodOrServ",
          "compName",
        ],
      },
    ],
    {
      locale: context.locale,
      fallbackLocales: context.defaultLocale,
      first: 100,
    }
  );
  let discounts: DiscountType[];
  try {
    discounts = await discountContentProvider.request(query, discountQueryName);
  } catch (e) {
    return { props: { discounts: null } };
  }
  if (discounts === undefined) {
    return { props: { discounts: null } };
  }

  const jobs = await jobsContentProvider.request(jobsQuery, jobsQueryName);

  let projects: any[] = [];
  try {
    projects = await getIndexProjects();
  } catch (e) {
    console.error("projects error", e);
  }
  let allDroners: any[] = [];
  try {
    allDroners = await getIndexDroners();
  } catch (e) {
    console.error("droners error", e);
  }

  // get events
  const db = new DbProvider();
  let events: (Event & { date: Timestamp })[] = [];
  try {
    events = await db.getAll(null, null, "events");
  } catch (e) {
    return {
      props: { events: [] },
    };
  }
  events = events.filter((event) => event.date.seconds);
  const modEvents: Event[] = events
    .map((event) => ({
      ...event,
      date: event.date.seconds * 1000,
    }))
    .sort((a, b) => a.date - b.date);

  return {
    props: { projects, allDroners, jobs, discounts, events: modEvents },
    revalidate: 3600,
  };
};

export default function Index({
  projects,
  allDroners,
  jobs,
  discounts,
  events,
}: {
  projects: IndexProjectType[];
  allDroners: IndexDronersType[];
  jobs: any;
  jobTypes: string[];
  discounts: DiscountType[];
  events: Event[];
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const promoStrings = localeStrings.uiGeneral.promoCards;
  const joinPromoStrings = promoStrings.filter((c) => c.promoId === "join")[0];

  const { setEvents } = useContext(EventsContext);
  setEvents(events);

  const homePageContent = localeStrings.homePage;
  // const loggedInBtnText = localeStrings.uiGeneral.droneJobsBoardLink;
  const offerDeadlineText = localeStrings.jobPage.offerDeadline;
  const smAndDown = useScreenSize("sm", "down");

  const { user, userData, dronerModel } = useUserData();

  return (
    <>
      <NextSeo
        title={homePageContent.title}
        description={homePageContent.description}
      />
      <Box sx={{ position: "relative" }}>
        {!smAndDown && <LargeLanding />}
        {smAndDown && <SmallLanding />}

        {/* Hero section*/}
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            maxWidth: "1248px !important",
          }}
        >
          {!smAndDown && (
            <LargeLandingHeroSection homePageContent={homePageContent} />
          )}
          {smAndDown && (
            <SmallLandingHeroSection homePageContent={homePageContent} />
          )}

          {/* WEBINAR SECTION */}
          <Webinar />
        </Container>
        {/* GET TO KNOW WITH POTENTIAL DRONERS SECTION */}
        <PotentialDroners />

        {/* Drone banner */}
        <DroneBanner smAndDown={smAndDown} />

        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            mb: 5,
            mt: 5,
            maxWidth: "1248px !important",
          }}
        >
          {/* Jobs*/}
          {!!jobs && (
            <div className="flex col">
              <HomeTitleSection
                link="/jobs"
                title={localeStrings.homePage.jobsSectionTitle}
                subtitle={localeStrings.homePage.jobsSectionSubtitle}
                itemQuantity={jobs.length}
              />
              <JobsSection jobs={jobs} offerDeadlineText={offerDeadlineText} />
            </div>
          )}
          {/* Discounts*/}
          {!!discounts && (
            <div className="flex col">
              <HomeTitleSection
                link="/discounts"
                title={localeStrings.discountsPage.pageTitle}
                subtitle={localeStrings.discountsPage.pageSubtitle}
                itemQuantity={discounts.length}
              />
              <DiscountsSection discounts={discounts} />
            </div>
          )}

          {/* TODO possible hydration error */}
          {!user && (
            <PromotionalCard
              title={joinPromoStrings.title}
              content={joinPromoStrings.description}
              imgUrl="/assets/community.webp"
              btnContent={joinPromoStrings.buttonText}
              left
              link="/register"
            />
          )}

          {/* Inspirations*/}
          <div className="flex col">
            <HomeTitleSection
              link="/inspirations"
              title={homePageContent.inspirationsSectionTitle}
              subtitle={homePageContent.inspirationsSectionSubtitle1}
              subtitle2={homePageContent.inspirationsSectionSubtitle2}
              itemQuantity={projects?.length}
            />
            <Inspirations />
          </div>
        </Container>

        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            mb: 5,
            mt: 5,
            maxWidth: "1248px !important",
          }}
        >
          {/* other droners*/}
          <div className="flex col">
            <HomeTitleSection
              link="/droners"
              title={homePageContent.discoverPilotsSectionsTitle}
              subtitle={homePageContent.discoverPilotsSectionsSubtitle}
              itemQuantity={allDroners?.length}
            />
            <OtherDronersSection allDroners={allDroners} />
          </div>

          <PromotionalCard
            title={joinPromoStrings.title}
            content={joinPromoStrings.description}
            content2={joinPromoStrings.optionalDescription}
            imgUrl="/assets/community-2.webp"
            btnContent={joinPromoStrings.buttonText}
            link="/register"
          />
        </Container>
      </Box>
    </>
  );
}
