import { Box, IconButton, Typography } from "@mui/material";
import { HomePage } from "../../types/cmsLocalizationTypes";
import { useRouter } from "next/router";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { EventsBannerDesktop } from "../Events/HomepageBanners";
import { useContext } from "react";
import { EventsContext } from "../../lib/context";

export const LargeLandingHeroSection = ({
  homePageContent,
}: {
  homePageContent: HomePage;
}) => {
  const router = useRouter();
  const locale = router.locale;
  const isSerbian = router.locale === "sr-Latn-RS";
  const { events } = useContext(EventsContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        alignItems: "flex-end",
      }}
      className="w-full mt-large"
    >
      <Box
        className="flex col align-items-end w-full justify-content-center"
        sx={{ gap: 5, height: "100%" }}
      >
        <Typography
          variant="h1"
          textAlign="right"
          sx={{ width: isSerbian ? "50%" : "40%" }}
          color="white"
        >
          {homePageContent.heroTitle}
        </Typography>
        <Typography
          variant="h2"
          textAlign="right"
          sx={{ width: "50%" }}
          color="white"
        >
          {homePageContent.subtitle}
        </Typography>
        <Box className="flex" sx={{ gap: 5 }}>
          <div>
            <button
              data-variant="large-outlined"
              onClick={() => router.push("/jobs")}
            >
              {homePageContent.button2Text}
            </button>
          </div>
          <div>
            <button
              onClick={() => router.push("/droners")}
              data-variant="large-outlined"
            >
              {homePageContent.button1Text}
            </button>
          </div>
        </Box>
        {events && events.length > 0 && <EventsBannerDesktop events={events} />}
      </Box>
      <Box
        className="flex col w-full align-items-center pb-large"
        sx={{ justifySelf: "flex-end" }}
      >
        <Box
          onClick={() => window.scrollTo({ top: 2800, behavior: "smooth" })}
          className="flex col align-items-center pointer"
        >
          <Typography variant="body1" textAlign="center" color="paper">
            {homePageContent.linkToJobsSection}
          </Typography>
          <IconButton>
            <ExpandMoreOutlinedIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
