import { Typography } from "@mui/material";
import Image from "next/legacy/image";
import DroneBannerForm from "./DroneBannerForm";
import { useContext } from "react";
import { LocalizationContext } from "../../lib/context";

const DroneBannerDesktop = ({
  showForm,
  formSubmitted,
  onSubmitForm,
  onCloseForm,
  onShowForm,
  userData,
}: {
  showForm: boolean;
  formSubmitted: boolean;
  onSubmitForm: () => void;
  onCloseForm: () => void;
  onShowForm: () => void;
  userData: any;
}) => {
  const { homePage } = useContext(LocalizationContext).localeStrings;
  const onShowFormHandler = () => {
    // if (!userData) {
    //   alert("Please login to send message.");
    //   return;
    // }

    onShowForm();
  };

  return (
    <div className="drone-banner__desktop">
      <div className="drone-banner__desktop--left">
        <div className="drone-banner__desktop--left-content">
          <Typography variant="h2" sx={{ fontSize: "2.8rem" }}>
            {homePage.chatSectionTitle}
          </Typography>

          <Typography variant="h3" sx={{ fontSize: "2rem", fontWeight: "600" }}>
            {homePage.chatSectionSubtitle}
          </Typography>

          <Typography
            className="subtitle-3"
            textAlign="right"
            sx={{ fontSize: "1.2rem", color: "#B5B5B5" }}
            children={homePage.chatSectionText}
          />

          {showForm && (
            <Typography variant="h2" sx={{ color: "#F4BF00", margin: 0 }}>
              {homePage.chatSectionButtonAfterText}
            </Typography>
          )}

          {!showForm && (
            <button
              onClick={onShowFormHandler}
              data-variant="primary-light"
              className="px-huge py-large"
              children={homePage.chatSectionButton}
            />
          )}
        </div>
      </div>
      <div className="drone-banner__desktop--right">
        {!showForm && (
          <>
            {formSubmitted && (
              <>
                <Typography
                  variant="h2"
                  className="text-center"
                  sx={{
                    fontSize: "2.3rem",
                    color: "textColors.main",
                    marginTop: "10rem",
                  }}
                  children={homePage.messageSent}
                />
                <Typography
                  className="px-large text-center"
                  sx={{
                    marginTop: "2rem",
                    fontSize: "1.5rem",
                    color: "textColors.light",
                    fontWeight: "bold",
                  }}
                  children={homePage.expertWillReply}
                />
              </>
            )}
            <Image
              alt="drone project"
              src="/assets/drone.webp"
              width={!formSubmitted ? "700" : "400"}
              height={!formSubmitted ? "299" : "299"}
              layout="intrinsic"
              priority
              color="inherit"
            />
          </>
        )}

        {showForm && (
          <DroneBannerForm
            onCloseForm={onCloseForm}
            onSubmitForm={onSubmitForm}
            isMobile={false}
            userData={userData}
          />
        )}
      </div>
    </div>
  );
};

export default DroneBannerDesktop;
