import Image from "next/legacy/image";
import { DiscountType } from "../../types/discountTypes";
import { Interval, Locale } from "date-fns";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import { useRouter } from "next/router";
import { Box, Card } from "@mui/material";
import MyShareIcon from "../MyShareIcon";
import { useContext, useEffect } from "react";
import { ShareLinkObject } from "../../lib/context";
import Link from "next/link";

export default function DiscountCard({
  discount,
  dateLocale,
}: {
  discount: DiscountType;
  dateLocale: Locale;
}) {
  const { push } = useRouter();
  const { shareLinkObject, setShareLinkObject } = useContext(ShareLinkObject);
  useEffect(() => {
    setShareLinkObject({
      title: discount.shortName,
      url: window.location.href,
    });
  }, [discount.shortName, setShareLinkObject]);

  const timeToEnd = new Date(discount.endDate).getTime() - new Date().getTime();
  let remainingTime: Duration | undefined;
  if (timeToEnd >= 0) {
    try {
      const intervalToEnd: Interval = {
        start: new Date(),
        end: new Date(discount.endDate),
      };
      remainingTime = intervalToDuration(intervalToEnd);
    } catch (e) {
      console.error(e);
    }
  }
  let timeLeftString = "";
  if (remainingTime === undefined) {
    timeLeftString += "end of promotion";
  } else {
    const formatDurOpts = { zero: true, locale: dateLocale };
    if (remainingTime.months && remainingTime.months > 0) {
      timeLeftString = formatDuration(remainingTime, {
        format: ["months", "days"],
        ...formatDurOpts,
      });
    } else if (remainingTime.days && remainingTime.days > 0) {
      timeLeftString = formatDuration(remainingTime, {
        format: ["days", "hours"],
        ...formatDurOpts,
      });
    } else if (remainingTime.hours && remainingTime.hours > 0) {
      timeLeftString = formatDuration(remainingTime, {
        format: ["hours", "minutes"],
        ...formatDurOpts,
      });
    } else {
      timeLeftString = formatDuration(remainingTime, {
        format: ["minutes", "seconds"],
        ...formatDurOpts,
      });
    }
  }

  return (
    <Card sx={{ maxWidth: "100%", boxShadow: 0 }}>
      <Box
        onClick={() => push(`/discounts/${discount.id}`)}
        className="pointer relative rounded-br-normal rounded-all-normal flex align-items-end"
        sx={{ height: 295 }}
      >
        <Image
          src={discount?.images[0].url}
          alt={discount?.images[0].alt}
          layout="fill"
          sizes="(max-width: 100%) 100%,
                 100%"
          objectFit="cover"
          quality={50}
          className="rounded-all-normal"
        />
        <div className="flex col w-full h-full justify-content-end">
          <Box
            sx={{ zIndex: 0 }}
            className="flex bg-card-linear w-full h-full col justify-content-end rounded-all-normal p-normal discount-hover"
          >
            <div className="flex discount-tag align-items-center mb-normal">{`${10}% OFF`}</div>
            <Box className="subtitle-5 text-surface">{discount.shortName}</Box>
          </Box>
        </div>
      </Box>
      <Box>
        <div className="flex col w-full justify-content-end ">
          <Box className="flex w-full align-items-center justify-content-space-between">
            <div className="flex col">
              <div
                className="text-text body-2 mt-normal text-no-wrap"
                style={{ fontSize: "12px", lineHeight: "13px" }}
              >
                {new URL(discount?.domain).hostname}
              </div>
              <div
                className="text-text  subtitle-5"
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: 600,
                }}
              >
                {timeLeftString}
              </div>
            </div>
            <div className="p-normal">
              <Link href="mailto:" style={{ color: "#4F4F4F" }}>
                <MyShareIcon linkObj={"shareLinkObject"} />
              </Link>
            </div>
          </Box>
        </div>
      </Box>
    </Card>
  );
}
