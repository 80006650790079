import { Button } from "@mui/material";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { LocalizationContext } from "../../lib/context";

const Webinar = () => {
  const { homePage, uiGeneral } = useContext(LocalizationContext).localeStrings;
  const router = useRouter();
  const locale = router.locale;

  return (
    <div className="webinar">
      <div className="webinar__content">
        <h1 className="blue-big-text">{homePage.webinarSectionTitle}</h1>
        <h1 className="blue-small-text">{homePage.webinarSectionSubtitle}</h1>
        <p>{homePage.webinarSectionText}</p>

        <Button
          type="submit"
          variant="contained"
          sx={{
            mb: locale === "hu" ? 5 : 2,
            backgroundColor: "primary.light",
            padding: "1rem 2rem",
          }}
          onClick={() => router.push("/register")}
        >
          {uiGeneral.registerButton}
        </Button>
      </div>
    </div>
  );
};

export default Webinar;
