import { Box, Container, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useContext } from "react";
import { LocalizationContext } from "../../lib/context";

const PotentialDroners = () => {
  const { homePage } = useContext(LocalizationContext).localeStrings;
  return (
    <div className="potential-droners">
      <div className="potential-droners__main">
        <Container className="potential-droners__content">
          <h2>{homePage.droneSectionTitle}</h2>
          <Divider color="white" />

          <Box
            sx={{
              gridTemplateColumns: "max-content 1fr",
              columnGap: "50px",
              display: { xs: "flex", md: "grid" },
              flexDirection: { xs: "column", md: "unset" },
              rowGap: "16px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                placeItems: { xs: "", md: "center" },
              }}
            >
              <div className="benefits">
                {homePage.droneSectionList.map((item, index) => (
                  <div key={index} className="benefits__item">
                    <CheckCircleIcon color="inherit" />
                    <h5>{item.listItem}</h5>
                  </div>
                ))}
              </div>
            </Box>

            <Box className="d-grid gap-large">
              <p className="subtitle-1">{homePage.droneSectionText1}</p>
              <p className="subtitle-1">{homePage.droneSectionText2}</p>
            </Box>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default PotentialDroners;
