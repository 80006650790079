import { Box } from "@mui/material";
import Image from "next/legacy/image";

export default function SmallLanding() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        zIndex: -1,
        height: "100vh",
      }}
      className="p-absolute w-full"
    >
      <Box
        sx={{
          minHeight: "50vh",
          mb: "-1px",
        }}
        className="bg-primary-lighten2 w-full"
      ></Box>
      <Box
        sx={{
          minHeight: "50vh",
        }}
        className="bg-sm-header-linear relative w-full"
      >
        <Image
          priority={true}
          alt={"drone project"}
          src="/assets/main_page_bg.webp"
          layout="fill"
          sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
          objectFit="cover"
          quality={50}
          style={{ zIndex: -1 }}
        />
      </Box>
    </Box>
  );
}
