import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DronerCard } from "../DronerCard";
import { IndexDronersType } from "../../types/indexTypes";
import ScrollXLayout from "../ScrollXLayout";

export default function OtherDronersSection({
  allDroners,
}: {
  allDroners: IndexDronersType[];
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        mt: 2,
        overflowX: { xs: "scroll", md: "visible" },
        flexDirection: { xs: "row", md: "column" },
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", md: "grid" },
          gridTemplateColumns: { xs: "repeat(8, 1fr)", md: "repeat(6, 1fr)" },
          // gap: 2,
        }}
        style={{ overflow: "hidden" }}
      >
        {isMobile ? (
          <ScrollXLayout>
            {allDroners
              ?.slice(0, 6)
              .map((droner: IndexDronersType, i: number) => (
                <Box
                  key={i}
                  sx={{
                    minWidth: 304,
                    [theme.breakpoints.down("md")]: {
                      width: 304,
                    },
                    pr: 4,
                  }}
                >
                  <DronerCard droner={droner} />
                </Box>
              ))}
          </ScrollXLayout>
        ) : (
          <>
            {allDroners
              ?.slice(0, 6)
              .map((droner: IndexDronersType, i: number) => (
                <Box
                  key={i}
                  sx={{
                    minWidth: 304,
                    [theme.breakpoints.down("md")]: {
                      width: 304,
                    },
                    pr: 2,
                  }}
                >
                  <DronerCard droner={droner} />
                </Box>
              ))}
          </>
        )}
      </Box>
    </Box>
  );
}
