import { Box } from "@mui/material";
import Image from "next/legacy/image";

export default function LargeLanding() {
  return (
    <Box
      sx={{
        display: "flex",
        zIndex: -1,
        height: "auto",
      }}
      className="p-absolute w-full"
    >
      <Box
        sx={{
          height: "100vh",
          mr: "-1px",
        }}
        className="bg-header-linear relative w-full"
      >
        <Image
          priority={true}
          alt={"drone project"}
          src="/assets/main_page_bg.webp"
          layout="fill"
          sizes="(min-width: 100vw) 100vw,
                 100vw"
          objectFit="cover"
          quality={50}
          style={{ zIndex: -1 }}
        />
      </Box>
      <Box className="bg-primary-lighten2 w-full"></Box>
    </Box>
  );
}
