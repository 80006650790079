import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../api_config/firebase-configuration";
import { useUserData } from "./useUserData";

export function useMessages() {
  const [messages, setMessages] = useState<any>(null);

  const { userData } = useUserData();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let allMessages: any;
    let unsub: any;
    if (userData) {
      unsub = onSnapshot(doc(db, "chat", userData.uid), (userDoc: any) => {
        allMessages = userDoc.data();

        setMessages(allMessages?.messages);
        setLoading(false);
      });
    }

    return (): void => unsub;
  }, [userData]);

  return { messages, loading };
}
