import { JobCard } from "./JobCard";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../api_config/firebase-configuration";
import { getJobState } from "../lib/global2";
import { useUserData } from "../hooks/useUserData";
import ScrollXLayout from "./ScrollXLayout";
import Skills from "./Skills";

export function JobsSection({
  jobs,
  offerDeadlineText,
}: {
  jobs: any[];
  offerDeadlineText: any;
}) {
  const { dronerModel } = useUserData();
  const [setAvailJobs, setSetAvailJobs] = useState(jobs);
  const [dronerJobs, setDronerJobs] = useState<Array<any>>([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<any>>([]);

  useEffect(() => {
    const getOffers = async () => {
      if (dronerModel) {
        const temp: any[] = [];
        const dronerTenderQuery = query(
          collection(db, "offers"),
          where("uid", "==", dronerModel.uid)
        );
        try {
          const snap = await getDocs(dronerTenderQuery);
          snap.forEach((doc) => {
            temp.push(doc.data());
          });
          setDronerJobs(temp);
        } catch (e) {
          console.error(e);
        }
      }
    };
    getOffers().catch();
  }, [dronerModel]);

  useEffect(() => {
    setSetAvailJobs(
      jobs
        .map((job) => ({
          ...job,
          state: getJobState(job, dronerJobs).sortNum,
        }))
        .filter((j) => j.state === 8)
    );
  }, [jobs, dronerJobs]);

  return (
    <>
      <Skills items={setAvailJobs} handleFilter={setFilteredJobs} />
      <ScrollXLayout>
        {filteredJobs.map((job: any) => (
          <JobCard
            job={job}
            offerDeadlineText={offerDeadlineText}
            key={job.id}
            dronerTenders={undefined}
          />
        ))}
      </ScrollXLayout>
    </>
  );
}
