import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { fetchAllUsersProjects } from "src/lib/global";
import InspirationHover from "../Inspirations/InspirationHover";
import Image from "next/legacy/image";

// Classnames for the inspirations in the homepage
const nineItems = [
  "m m-1",
  "s s-1",
  "s s-2",
  "s s-3",
  "xs xs-1",
  "l",
  "xs xs-2",
  "m m-2",
  "xs xs-3",
];

const Inspirations = () => {
  const [projects, setProjects] = useState<any[]>([]);
  const { query } = useRouter();
  const isFeedback = query.feedback || false;
  const otc =
    typeof query.otc === "string" ? query.otc?.replace(" ", "") || "" : "";

  // FETCH DRONERS' PROJECTS
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAllUsersProjects();
      setProjects(data);
    };
    fetchData();
  }, []);

  return (
    <div className="inspire-section">
      {projects &&
        nineItems.map((item, i) => (
          <div className={item + " inspire-section__item"} key={item}>
            {projects[i]?.images && projects[i]?.images.length > 0 && (
              <Image
                layout="fill"
                src={projects[i]?.images[0]}
                alt="project image"
              />
            )}
            <InspirationHover
              title={projects[i]?.title}
              ratings={projects[i]?.feedback || 0}
              skills={projects[i]?.skills[0]}
              project={projects[i]}
              isFeedback={
                isFeedback &&
                otc === projects[i]?.feedbackCode &&
                !projects[i]?.feedbackGiven
              }
            />
          </div>
        ))}
    </div>
  );
};

export default Inspirations;
