import { useState } from "react";
import DroneBannerDesktop from "src/components/DroneBanner/DroneBannerDesktop";
import DroneBannerMobile from "src/components/DroneBanner/DroneBannerMobile";
import { useUserData } from "src/hooks/useUserData";

export const DroneBanner = ({
  smAndDown,
}: {
  smAndDown: boolean | undefined;
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { userData } = useUserData();

  // Closing and shoring form
  const onCloseForm = () => setShowForm(false);
  const onShowForm = () => {
    setShowForm(true);
    setFormSubmitted(false);
  };

  //
  const onSubmitForm = () => setFormSubmitted(true);

  return (
    <>
      <div className="drone-banner">
        {/* DESKTOP BANNER */}
        {!smAndDown && (
          <DroneBannerDesktop
            showForm={showForm}
            formSubmitted={formSubmitted}
            onSubmitForm={onSubmitForm}
            onCloseForm={onCloseForm}
            onShowForm={onShowForm}
            userData={userData}
          />
        )}

        {/* MOBILE BANNER */}
        {smAndDown && (
          <DroneBannerMobile
            showForm={showForm}
            formSubmitted={formSubmitted}
            onSubmitForm={onSubmitForm}
            onCloseForm={onCloseForm}
            onShowForm={onShowForm}
            userData={userData}
          />
        )}
      </div>
    </>
  );
};
