import { Box, Button, Typography } from "@mui/material";
import { Event } from "../../types/eventTypes";
import { useRouter } from "next/router";
import { useContext } from "react";
import { LocalizationContext } from "../../lib/context";

const TextBlock = ({ event }: { event: Event }) => {
  const { upcomingEvent } =
    useContext(LocalizationContext).localeStrings.homePage;
  return (
    <>
      <Typography fontSize={22} fontWeight="bold" color="white">
        {upcomingEvent}
      </Typography>
      <Typography mt={1} fontSize={16} fontWeight="bold" color="white">
        {event?.name}
      </Typography>
    </>
  );
};

const CalendarButton = () => {
  const router = useRouter();
  const { calendarButton } =
    useContext(LocalizationContext).localeStrings.homePage;
  return (
    <Button
      sx={{ mt: { sm: 2, xs: 1 }, color: "white", borderColor: "white" }}
      variant="outlined"
      onClick={() =>
        router.push(
          "/" + (router.locale !== "en" ? router.locale : "") + "/events"
        )
      }
    >
      {calendarButton}
    </Button>
  );
};

export const EventsBannerDesktop = ({ events }: { events: Event[] }) => {
  return (
    <Box
      textAlign="center"
      sx={{
        borderRadius: "30px",
        p: 2,
        minWidth: "455px",
      }}
    >
      <TextBlock event={events[0]} />
      <CalendarButton />
    </Box>
  );
};

export const EventsBannerMobile = ({ events }: { events: Event[] }) => {
  return (
    <Box
      textAlign="center"
      sx={{
        opacity: 0.8,
        backgroundColor: "primary.main",
        borderRadius: "20px",
        p: 1,
      }}
    >
      <TextBlock event={events[0]} />
      <CalendarButton />
    </Box>
  );
};
