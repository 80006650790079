import { Box, Modal, Typography } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ArrowIcon from "../Icons/ArrowIcon";
import ShareIcon from "../Icons/ShareIcon";
import ProjectContent from "../Project/ProjectContent";
import { useState } from "react";
import { modalStyle } from "src/styles/globalStyles";

const InspirationHover = ({ title, ratings, skills, project, isFeedback }) => {
  const [openReadOnly, setOpenReadOnly] = useState(false);

  return (
    <>
      <div className="hover" onClick={() => setOpenReadOnly(true)}>
        <Typography
          variant="subtitle2"
          color="white"
          sx={{ textTransform: "uppercase" }}
          children={title}
        />
        <div className="hover__rating">
          {[1, 2, 3, 4, 5].map((number) => (
            <StarRoundedIcon
              full={(ratings >= number && true).toString()}
              key={number}
            />
          ))}
        </div>

        <div className="hover__type">
          <h5>{skills}</h5>
        </div>

        <div className="hover__bottom">
          <div>
            <a
              onClick={(e) => e.stopPropagation()}
              href="mailto:info@dronecakes.com"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ShareIcon color="#1C1C1C" />
            </a>
          </div>
          <div onClick={() => setOpenReadOnly(true)}>
            <ArrowIcon type="right" color="#1C1C1C" />
          </div>
        </div>
      </div>
      <Modal open={openReadOnly} onClose={() => setOpenReadOnly(false)}>
        <Box className="bg-surface" sx={{ ...modalStyle }}>
          <ProjectContent
            project={project}
            isFeedback={isFeedback}
            close={() => setOpenReadOnly(false)}
          />
        </Box>
      </Modal>
    </>
  );
};

export default InspirationHover;
