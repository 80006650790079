import { useContext, useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import { LocalizationContext } from "../lib/context";

export default function MyShareIcon({ linkObj }: { linkObj: any }) {
  const { localeStrings } = useContext(LocalizationContext);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [canShare, setCanShare] = useState(true);

  useEffect(() => {
    setCanShare(!!navigator && !!navigator.share);
  }, []);

  async function handleClick() {
    if (canShare) {
      try {
        await navigator.share(linkObj);
      } catch (e: any) {
        // console.error(e.toString());
      }
    } else {
      navigator.clipboard?.writeText(linkObj.url).then(() => {
        setOpenToolTip(true);
      });
    }
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpenToolTip(false);
      }}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={openToolTip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="top-end"
        title={localeStrings.uiGeneral.copied}
      >
        <Box
          onClick={() => handleClick()}
          sx={{
            width: "32px",
            height: "32px",
            filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))",
            zIndex: 1,
          }}
          className="flex p-small bg-surface small-shadow rounded-all-full align-items-end pointer justify-content-center"
        >
          {!canShare ? (
            <ContentCopyIcon sx={{ width: 20 }} />
          ) : (
            <IosShareIcon sx={{ width: 20 }} />
          )}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
}
