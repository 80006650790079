import { Box } from "@mui/system";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { LocalizationContext } from "../lib/context";

type Props = {
  items: string[];
  handleFilter: Dispatch<SetStateAction<any[]>>;
};

// const Skills = ({ skills, activeSkill, handleActive }: Props) => {
const Skills = ({ items, handleFilter }: Props) => {
  const { uiGeneral } = useContext(LocalizationContext).localeStrings;
  const [activeSkill, setActiveSkill] = useState("all");
  const [itemTypes, setItemTypes] = useState<Array<any>>([]);

  useEffect(() => {
    setItemTypes([
      ...new Set(items.map((item: any) => item.projectType)),
      "all",
    ]);
  }, [items]);

  useEffect(() => {
    handleFilter(
      activeSkill === "all"
        ? items
        : items.filter(
            (item: any) =>
              item.projectType.toLowerCase() === activeSkill.toLowerCase()
          )
    );
  }, [activeSkill, handleFilter, items]);

  return (
    <>
      <Box
        sx={{
          overflowX: "scroll",
          display: "grid",
          gridAutoFlow: "column",
          gridAutoColumns: "max-content",
        }}
      >
        {itemTypes?.map((skill) => {
          const localizedSkillName =
            skill === "all"
              ? uiGeneral.all
              : uiGeneral.skills.filter((s) => s.skillId === skill)[0]?.name;
          return (
            <React.Fragment key={skill}>
              <Box
                sx={{
                  display: "inline-block",
                  border: `${activeSkill === skill ? "2px" : "1px"} solid`,
                  borderColor: `${
                    activeSkill === skill ? "primary.light" : "textColors.light"
                  }`,
                  borderRadius: "100px",
                  padding: "1px 10px",
                  margin: "10px",
                  marginLeft: 0,
                  cursor: "pointer",
                  color: `${
                    activeSkill === skill ? "primary.light" : "textColors.main"
                  }`,
                }}
              >
                <span
                  onClick={() => setActiveSkill(skill)}
                  style={{ fontSize: "16px" }}
                >
                  {localizedSkillName?.toLowerCase()}
                </span>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
};

export default Skills;
