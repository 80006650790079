import { Box, FormControl, TextField, Typography } from "@mui/material";
import React, { FormEvent, useContext, useState } from "react";
import { useMessages } from "src/hooks/useMessages";
import DropDown from "../DropDown";
import { doc, setDoc } from "firebase/firestore";
import { db } from "api_config/firebase-configuration";
import MailOutline from "@mui/icons-material/MailOutline";
import Image from "next/image";
import { LoadingButton } from "@mui/lab";
import { LocalizationContext } from "../../lib/context";
import { useUserData } from "src/hooks/useUserData";

const DroneBannerForm = ({
  onCloseForm,
  onSubmitForm,
  isMobile,
  userData,
}: {
  onCloseForm: () => void;
  onSubmitForm: () => void;
  isMobile: boolean;
  userData: any;
}) => {
  const { homePage, uiGeneral } = useContext(LocalizationContext).localeStrings;
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [selectedProblem, setSelectedProblem] = useState<string>("");
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(() => false);
  const [loading, setLoading] = useState(() => false);
  const { actDroner } = useUserData();

  const visibleProblems = () => {
    const problemTypes = ["Általános"];
    if (actDroner?.subscriptionLevel)
      problemTypes.push(actDroner.subscriptionLevel);
    return problemTypes;
  };

  // Custom hooks
  const { messages } = useMessages();

  // Submit the message
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!message || !email || !selectedProblem) {
      alert("Please fill the form");
      return;
    }

    setLoading(true);

    // FORMATING TIME AND DATE
    const time = new Date()
      .toLocaleTimeString("en-Us", { hour12: false })
      .split(":")
      .splice(0, 2)
      .join(":");
    const date = new Date().toLocaleDateString().split("/");
    const formattedDate = `${date[2]}/${date[0]}/${date[1]}`;

    // If user has old messages, we merge it with new message, If not, we just save the new and first message
    const newMessageObject = {
      time,
      date: formattedDate,
      message: message,
      fromClient: true,
      messageSentTime: new Date().getTime(),
      type: "text",
      problem: selectedProblem,
      email,
    };
    const updatedMessages = messages
      ? [...messages, newMessageObject]
      : [newMessageObject];

    await setDoc(
      doc(db, "chat", userData?.uid ? userData?.uid : newMessageObject.email),
      { messages: updatedMessages },
      { merge: true }
    );

    setMessage("");
    onCloseForm();
    onSubmitForm();
    setLoading(false);
  };

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <Image
            src="/assets/arrowLeftBlue.png"
            alt=""
            width="20"
            height="20"
            onClick={onCloseForm}
            style={{ cursor: "pointer" }}
          />
          <Typography variant="h2" sx={{ fontSize: "1.6rem" }}>
            Kérdezz szakértőnktől
          </Typography>
        </Box>
      )}

      <FormControl fullWidth sx={{ display: "grid", rowGap: "16px" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: "8px",
            width: "100%",
            maxWidth: "390px",
          }}
        >
          <label className="subtitle-5">{homePage.emailLabel} *</label>

          <TextField
            type="email"
            placeholder={homePage.emailPlaceholder}
            onChange={(e) => setEmail(e.target.value)}
            className="settingsInput__input"
            defaultValue={email}
          />
        </Box>

        <Box sx={{ display: "grid", rowGap: "8px", maxWidth: "390px" }}>
          <DropDown
            placeholder={homePage.typePlaceholder}
            label={homePage.typeLabel + " *"}
            dropDownOpen={dropDownOpen}
            onToggle={() => setDropDownOpen((prevState) => !prevState)}
            onClose={() => setDropDownOpen(false)}
            selectedProblem={selectedProblem}
            problemTypes={visibleProblems()}
            setSelectedProblem={(item) => setSelectedProblem(item)}
          />
        </Box>

        <Box sx={{ display: "grid", rowGap: "8px" }}>
          <h5 className="subtitle-5">{homePage.messageBodyLabel} *</h5>
          <TextField
            multiline
            rows={isMobile ? 6 : 10}
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ maxWidth: "570px" }}
          />
        </Box>
      </FormControl>

      <LoadingButton
        type="submit"
        variant="contained"
        startIcon={<MailOutline />}
        loading={loading}
        sx={{
          backgroundColor: "primary.light",
          padding: "1rem 2rem",
          marginTop: "1rem",
        }}
        className={isMobile ? "w-full" : ""}
        children={uiGeneral.sendButton}
      />
    </form>
  );
};

export default DroneBannerForm;
