import { Box } from "@mui/material";
import Image from "next/image";

const DropDown = ({
  dropDownOpen,
  selectedProblem,
  onToggle,
  onClose,
  problemTypes,
  setSelectedProblem,
  label,
  placeholder,
}: {
  dropDownOpen: boolean;
  onToggle: () => void;
  onClose: () => void;
  selectedProblem: string;
  problemTypes: string[];
  setSelectedProblem: (e: string) => void;
  label: string;
  placeholder: string;
}) => {
  return (
    <Box sx={{ display: "grid", rowGap: "8px" }}>
      <h5 className="subtitle-5">{label}</h5>
      <div className={`dropdown${dropDownOpen ? " dropdown-open" : ""}`}>
        <div className="dropdown__main" onClick={onToggle}>
          <h6 className="dropdown__placeholder">
            {selectedProblem ? selectedProblem : placeholder}
          </h6>
          <Image alt="" src="/assets/arrow-down.svg" width={24} height={24} />
        </div>

        {dropDownOpen && (
          <ul className="dropdown__options">
            {problemTypes.map((item, i) => (
              <li
                key={i}
                onClick={() => {
                  setSelectedProblem(item);
                  onClose();
                }}
              >
                <h6>{item}</h6>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Box>
  );
};

export default DropDown;
