import { Box, IconButton, Typography } from "@mui/material";
import { HomePage } from "../types/cmsLocalizationTypes";
import { useRouter } from "next/router";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { EventsBannerMobile } from "./Events/HomepageBanners";
import { useContext } from "react";
import { EventsContext } from "../lib/context";

export const SmallLandingHeroSection = ({
  homePageContent,
}: {
  homePageContent: HomePage;
}) => {
  const router = useRouter();
  const locale = router.locale;
  const { events } = useContext(EventsContext);
  return (
    <Box
      sx={{
        justifyContent: "center",
        height: "100vh",
        alignItems: "flex-end",
      }}
      className="w-full flex col"
    >
      <Box
        className="flex col h-full w-full"
        sx={{ justifyContent: "space-between", mt: "74px" }}
      >
        <Box sx={{ textAlign: "center", justifyContent: "center" }}>
          <Typography
            variant="h1"
            color="white"
            sx={{ lineHeight: "52px", fontSize: "34px" }}
          >
            {homePageContent.heroTitle}
          </Typography>
          <Typography
            variant="h3"
            // textAlign="center"
            sx={{
              textSize: "16px",
              fontWeight: 700,
              width: "215px",
              mx: "auto",
              lineHeight: "25.58px",
              mt: "16px",
            }}
            color="white"
          >
            {homePageContent.subtitle}
          </Typography>
        </Box>
        <Box
          className="flex w-full col-rev align-items-center"
          sx={{ mb: "30px", gap: 3 }}
        >
          {events && events.length > 0 && (
            <EventsBannerMobile events={events} />
          )}
          <div>
            <button
              onClick={() => router.push("/jobs")}
              data-variant="large-outlined"
            >
              {homePageContent.button2Text}
            </button>
          </div>
          <div>
            <button
              onClick={() => router.push("/droners")}
              data-variant="large-outlined"
            >
              {homePageContent.button1Text}
            </button>
          </div>
        </Box>
      </Box>
      <Box
        onClick={() => window.scrollTo({ top: 640, behavior: "smooth" })}
        className="flex col w-full align-items-center pointer mt-normal"
      >
        <IconButton>
          <ExpandMoreOutlinedIcon sx={{ color: "white" }} fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
};
