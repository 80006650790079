import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import DroneBannerForm from "./DroneBannerForm";
import { LocalizationContext } from "../../lib/context";

const DroneBannerMobile = ({
  showForm,
  onCloseForm,
  onSubmitForm,
  formSubmitted,
  onShowForm,
  userData,
}: {
  showForm: boolean;
  onCloseForm: () => void;
  onSubmitForm: () => void;
  onShowForm: () => void;
  formSubmitted: boolean;
  userData: any;
}) => {
  const { homePage } = useContext(LocalizationContext).localeStrings;
  const onShowFormHandler = () => {
    onShowForm();
  };

  return (
    <div className="drone-banner__mobile">
      {!showForm && !formSubmitted && (
        <div className="drone-banner__mobile--front text-center flex col gap-large">
          <Typography
            variant="h2"
            sx={{ fontSize: "2.3rem", margin: "40px 0" }}
            children={homePage.chatSectionTitle}
          />

          <Typography
            variant="h3"
            sx={{
              fontSize: "1.4rem",
              fontWeight: "bold",
              color: "textColors.light",
            }}
            children={homePage.chatSectionSubtitle}
          />

          <Typography
            className="subtitle-3"
            sx={{ fontSize: "1rem", color: "textColors.light" }}
            children={homePage.chatSectionText}
          />
          <Typography
            variant="h2"
            sx={{ color: "secondary.main", fontSize: "1.2rem" }}
            children={homePage.chatSectionButtonAfterText}
          />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={onShowFormHandler}
              data-variant="primary-light"
              className="px-large py-large"
              children={homePage.chatSectionButton}
            />
          </Box>
        </div>
      )}

      {formSubmitted && (
        <div className="drone-banner__mobile--back">
          <div className="drone-banner__mobile--back-content">
            <h2 className="text-center">{homePage.messageSent}</h2>

            <Typography className="px-large text-center">
              {homePage.expertWillReply}
            </Typography>

            <img src="/assets/drone-mobile.png" alt="" />
          </div>
        </div>
      )}

      {showForm && (
        <div className="drone-banner__mobile--back">
          <DroneBannerForm
            onCloseForm={onCloseForm}
            onSubmitForm={onSubmitForm}
            isMobile={true}
            userData={userData}
          />
        </div>
      )}
    </div>
  );
};

export default DroneBannerMobile;
